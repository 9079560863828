<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/加盟-授权-二次.jpg" width="100%" height="100%" lazy>
    <div class="size_box">
      <div class="title">授权说明</div>
      <div class="title_s">关于网络购买智驹产品的声明</div>

      <h3>至广大消费者:</h3>
      <p>近日，我司收到客户投诉，称在淘宝网上买到的摄像机产品型号与智驹官网公布的产品型号不一致，且成像效果达不到指定线数标准</p>
      <p>鉴于此，我司特此发公告提醒消费者，在网购智驹产品时应在经智驹核实及授权的店铺购买，经智驹核实及授权的店铺，我们将发放如下网购专用授权书。请各位消费者在购物时辨别商家是否具有此授权书。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "授权说明"
}
</script>

<style scoped>
.size_box {
  width: 80%;
  margin: auto;
}
.title_s{
  margin-top: 30px;
  text-align: center;
  font-size:16px;
}
.title {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}
</style>